.container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.logo img {
    width: 215px;
    margin-bottom: 100px;
}

.first-section {
    background: var(--Dark-Purple-900, #290064) url('../assets/BG.png') center/cover;
    position: relative;
    z-index: 1;
    /* height: 812px; */
    border-bottom: 10px solid #02F3A2;
}

.first-section-row {
    padding-bottom: 100px;
}

.second-section-row {
    padding-top: 100px;
    padding-bottom: 100px;
}

.third-section-row {
    padding-top: 100px;
    padding-bottom: 100px;
}


.fourth-section-row {
    padding-top: 100px;
    padding-bottom: 100px;
}

.fifth-section-row {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section-container {
    z-index: 2;
    position: relative;
}

.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 50px;
    padding-right: 50px;
}


/* new */
.c-s-text {
    -webkit-text-stroke-width: 2;
    -webkit-text-stroke-color: #000;
    font-family: Inter;
    font-size: 168px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 168px */
}

.f-s-text-2 {
    color: var(--Green-500, #02F3A2);

    /* Heading/H1/Bold */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    /* 76.8px */
}

.f-s-card,
.w-c-card {
    position: relative;
    border-radius: 24px;
    background: rgba(2, 243, 162, 0.06);
    backdrop-filter: blur(10px);
    padding: 35px 10px;
    width: 95%;
    border-top: 1px solid #02F3A2;
    border-left: none;
    /* Remove the left border */
    border-right: none;
    /* Remove the right border */
    border-bottom: none;
    /* Remove the bottom border */
}

.f-s-card::before,
.f-s-card::after {
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 24px;
}

/* Gradient borders on the sides */
.f-s-card::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 24px;
    background:
        linear-gradient(to bottom, #02F3A2, transparent),
        /* Left border fade */
        linear-gradient(to bottom, #02F3A2, transparent);
    /* Right border fade */
    background-repeat: no-repeat;
    background-position: left, right;
    background-size: 1px 100%, 2px 100%;
    /* Border width and full height */
}




.f-s-card-heading {
    color: var(--Purple-50, #EFE6FD);
    text-align: center;
    width: 75%;
    margin: auto;
    /* Heading/H4/Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    /* 38.4px */
}

.f-s-card-text {
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    color: var(--Purple-50, #EFE6FD);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 32px */
}

.h-text {
    color: var(--Green-500, #02F3A2);
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    /* 76.8px */
}

.w-c-card {
    border-radius: 24px;
    background: rgba(2, 243, 162, 0.06);
    backdrop-filter: blur(10px);
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    min-height: 337px;
}

.w-c-card::before,
.w-c-card::after {
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 24px;
}

/* Gradient borders on the sides */
.w-c-card::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 24px;
    background:
        linear-gradient(to bottom, #02F3A2, transparent),
        /* Left border fade */
        linear-gradient(to bottom, #02F3A2, transparent);
    /* Right border fade */
    background-repeat: no-repeat;
    background-position: left, right;
    background-size: 1px 100%, 2px 100%;
    /* Border width and full height */
}

.w-c-icon {
    position: relative;
    display: inline-block;
    /* or 'block' depending on your layout */
}

.w-c-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* This should be the size of your icon */
    height: 100%;
    /* This should be the size of your icon */
    background-color: #02F3A2;
    border-radius: 50%;
    /* makes the glow round, remove if not needed */
    filter: blur(75px);
    z-index: -1;
    /* Ensure the glow is behind the icon */
}


.w-c-icon img {
    display: block;
    width: 136.236px;
    height: 150px;
    position: relative;
    z-index: 1;

}


.w-c-text {
    color: var(--Green-50, #E6FEF6);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 38.4px */
}

.t-r-card {
    border-radius: 24px;
    border: 1px solid #02F3A2;
    background: rgba(2, 243, 162, 0.06);
    backdrop-filter: blur(10px);
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    border-top: 1px solid #02F3A2;
    border-left: none;
    /* Remove the left border */
    border-right: none;
    /* Remove the right border */
    border-bottom: none;
}

.t-r-card::before,
.t-r-card::after {
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 24px;
}

.t-r-card::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 24px;
    background:
        linear-gradient(to bottom, #02F3A2, transparent),
        /* Left border fade */
        linear-gradient(to bottom, #02F3A2, transparent);
    /* Right border fade */
    background-repeat: no-repeat;
    background-position: left, right;
    background-size: 1px 100%, 2px 100%;
    /* Border width and full height */
}

.t-r-card-heading {
    color: var(--Green-500, #02F3A2);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    padding-bottom: 50px;
}

.t-r-card-button {
    border-radius: 30px;
    background: var(--Green-500, #02F3A2);
    color: var(--Dark-Purple-900, #11002A);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    padding: 10px 10px;
    cursor: pointer;
}

.w-p-btn {
    border-radius: 30px;
    background: var(--Green-500, #02F3A2);
    color: var(--Dark-Purple-900, #11002A);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    padding: 10px 35px;
    cursor: pointer;
    width: fit-content;
    margin: auto;
}

.social-btn-container {
    display: flex;
}

.social-btn {
    width: 400px;
    border-radius: 30px;
    background: var(--Green-500, #02F3A2);
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: var(--Dark-Purple-900, #11002A);
    text-align: center;
    padding: 10px 10px;
    /* Heading/H5/Semi Bold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    cursor: pointer;
    /* 32px */
}

.h2-text {
    color: var(--Green-500, #02F3A2);
    text-align: center;

    /* Heading/H4/Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    /* 38.4px */
}

@media (max-width: 768px) {
    .f-s-card, .w-c-card{
        margin: auto;
    }
    .logo img {
        margin-bottom: 30px;
    }

    .c-s-text img {
        width: 100%;
    }

    .f-s-text-2 {
        font-size: 25px;
    }

    .f-s-card-text {
        font-size: 17px;
    }

    .section-padding {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: unset;
        padding-right: unset;
    }

    .h-text {
        font-size: 30px;
    }

    .social-btn-container {
        display: block;
    }

    .social-btn {
        width: unset;
        font-size: 16px;
    }

    .w-c-text {
        font-size: 17px;
    }

    .w-c-card {
        min-height: unset;
    }

    .w-c-icon img {
        width: 70px;
        height: unset;
    }

    .t-r-card-heading {
        font-size: 17px;
    }

    .t-r-card-button {
        font-size: 17px;
        padding: 8px 0px;
    }

    .w-p-btn {
        font-size: 17px;
    }

    .f-s-card-heading {
        font-size: 19px;
    }

    .first-section-row,
    .second-section-row,
    .third-section-row,
    .fourth-section-row {
        padding-bottom: unset;
    }
}